define("discourse/plugins/response-bot/discourse/pre-initializers/extend-category-for-response-bot", ["exports", "ember-addons/ember-computed-decorators", "discourse/models/category"], function (_exports, _emberComputedDecorators, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'extend-category-for-response-bot',
    before: 'inject-discourse-objects',
    initialize() {
      _category.default.reopen(dt7948.p({
        enable_response_bot: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.enable_response_bot", value);
            return value;
          }
        },
        disable_response_bot: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.disable_response_bot", value);
            return value;
          }
        }
      }, [["field", "enable_response_bot", [(0, _emberComputedDecorators.default)('custom_fields.enable_response_bot')]], ["field", "disable_response_bot", [(0, _emberComputedDecorators.default)('custom_fields.disable_response_bot')]]]));
    }
  };
});